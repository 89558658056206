/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #193983;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1900ff;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  left: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center
}

.float:hover, .my-float:hover {
  color: #FFF;
}

.header-logo {
  width: 150px;
}

.video {
  width: auto;
  height: 100%
}